header#global-header {
	width: 100%;
	height: 55px;
  background: rgba(247, 247, 247, 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

	// position: fixed;
	// width: 100%;
	// height: 55px;
	// background: rgba(247, 247, 247, 1);
	// z-index: 1000;

	h1 {
		width: 140px;
		position: absolute;
		top: -3px;
		right: 30px;
		a {
			display: block;
		}
	}
	nav {
			// width: 250px;
			height: 75%;
			width: 70%;
			background: #483D39;
			position: fixed;
			top: 55px;
			left: 0;
			z-index: 10;
			transform: translateX(-400px); /* right0から250px移動した位置 */
			transition: all .5s; /* 移動する速さ */
			@include breakpoint(medium-up) {
				width: 35%;
				height: 74%;
			}
		
		// background: #483D39;
		// position: fixed;
		// z-index: 90;
		// top: 0;
		// visibility: hidden;
		// opacity: 0;
		// -webkit-transition: all .5s;
		// transition: all .5s;
		// display: flex;
		// flex-direction: column;

		ul {
    		padding: 0;
			margin: 0 30px;
			margin-top: 40px;
		  	text-align: center;
			li {
				font-size: 14px;
				font-weight: bold;
				padding: 20px 20px;
				border-bottom: 0.5px solid #fff;
				a {
					color: #fff;
					letter-spacing: 3px;
				}
			}
			.top-line {
				// border-top: 1px solid #fff;
				a {
					display: inline-block;
					text-decoration: none;
					text-align: center;
					// padding: 5px 10px;
					position: relative;
				}
				a::after {
					content: '';
					display: block;
				  
					/* 初期状態でのwidthは0 */
					width: 0;
					height: 2px;
					background: #ffe500;
					position: absolute;
					bottom: 0;
					
					/* leftを0にすることで左から線が伸びる   */
					left: 0;
					/* ゆっくり線がのびるようにする   */
					transition: .2s;
					@include breakpoint(large-up) {
						transition: .5s;
					}
				}
				/* マウスカーソルが乗っかるとwidthが100%に伸びる */
				a:hover::after {
					width: 100%;
				}
			}
		}
	}
	
	#toggle {
		position: absolute;
		left: 17px;
		top: 18px;
		width: 31px;
		cursor: pointer;
		z-index: 100;
		.trigger {
			position: relative;
		}
		span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: #483D39;
			left: 0;
			-webkit-transition: .35s ease-in-out;
			-moz-transition: .35s ease-in-out;
			transition: .35s ease-in-out;
			&:nth-child(1) {
				top: 0;
			}
			&:nth-child(2) {
				top: 10px;
			}
			&:nth-child(3) {
				top: 20px;
			}
		}
	}
	
	&.open {
		nav {
			transform: translateX(0);
			// visibility: visible;
			// opacity: 1;
			// padding: 0;
			width: 70%;
			height: 75%;
			top: 55px;
			@include breakpoint(medium-up) {
				width: 35%;
				height: 74%;
			}
		}
		#toggle {
			span {
				background: #483D39;
		    	&:nth-child(1) {
					top: 11px;
					-webkit-transform: rotate(315deg);
					-moz-transform: rotate(315deg);
					transform: rotate(315deg);
		    	}
				&:nth-child(2) {
					width: 0;
					left: 50%;
				}
				&:nth-child(3) {
					top: 11px;
					-webkit-transform: rotate(-315deg);
					-moz-transform: rotate(-315deg);
					transform: rotate(-315deg);
				}
			}
		}
	}
	
	
	@include breakpoint(large-up) {
		position: sticky;
		top: 0;
		height: 100vh;
		h1 {
			width: 80%;
			top: 70px;
			left: 19px;
		}
		#toggle {
			display: none;
		}
	  	nav {
				transform: none;
			background: none;
			height: auto;
			width: 100%;
			height: 100%;
			position: static;
			padding: 0;
			visibility: visible;
			opacity: 1;
			ul {
				margin-top: 110%;
				li {
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 1px;
					margin-bottom: 45px;
					padding: 0;
					border: none;
					a {
						color: $dark-gray;
						&:hover {
							color: $black;
						}
					}
					.top-line {
						border: none;
					}
				}
	  		}
		}
	}






}