body.home {
	main {
		margin-top: 0;
		@include breakpoint(large-up) {
			margin-top: 20px;
		}
	}
	// .top-block {
	// 	@include breakpoint(large-up) {
	// 		overflow: hidden;
	// 		display: flex;
	// 	}
	// }

	#welcome {
		.picture {
				background-image: url(../images/officeimg1.jpg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				width: 100%;
				height: 100%;
		}
		
		@include breakpoint(small-only) {
			height: 330px;
		}
		
		@include breakpoint(medium-up) {
			height: 500px;
		}
		
		@include breakpoint(large-up) {
			// margin-right: 20px;
			// width: 74%;
		}
	}

	#news {
		.container {
			@include breakpoint(large-up) {
				padding: 0;
				margin-bottom: 0;
			}
		}
		@include breakpoint(large-up) {
			width: 25%;
		}
		h2 {
			@include breakpoint(large-up) {
				margin-top: 0;
			}
		}
		.news-list {
			li {
				border-bottom: 1px solid #B7B7B7;
				margin-bottom: 20px;
				time {
					color: #B7B7B7;
					font-size: 14px;
				}
				h5 {
					color: #000;
					font-weight: normal;
				}
				h5:hover {
					color: #ffe500;
				}
			}
		}
	}


	#works {
		.container {
			@include breakpoint(large-up) {
				padding: 0;
			}
		}
		h2 {
			@include breakpoint(large-up) {
				margin-top: 70px;
				margin-bottom: 50px;
			}
		}
		
		.button-area {
			margin-bottom: 70px;
			margin-top: 50px;
		}
	}
}









