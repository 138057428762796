.single {
    .content_body {
        .breadcrumbs {
            font-size: 14px;
            margin-bottom: 40px;
            color: #B7B7B7;
        }
        .category-child {
            display: inline-block;
            background: #ffe500;
            padding: 2px 16px;
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 10px;
        }
        .blog-content {
            p {
                // margin-bottom: 30px;
                text-align: right;
                a {
                    color: #000;
                    transition: color;
                    text-decoration: underline;
                }
                a:hover{
                    color : #FFE500;
                }
            }
            figure {
                margin: 0 20px;
                margin-bottom: 50px;
                box-shadow: 4px 8px 20px -8px #000;
            }
            figure:not(:last-child) {
                margin-bottom: 50px;
            }
            figure:first-child {
                margin-top: 30px;
            }

        }
        @include breakpoint(medium-up) {
            max-width: 700px;
            margin: 0 auto;

            .blog-content {
                p {
                    margin-bottom: 50px;
                }
            }
        }
        @include breakpoint(large-up) {
            // max-width: 850px;
            margin: 0 100px;
        }
    }


    // .content_body {
    //     max-width: 860px;
    //     margin: 0 auto;
    // }
     
}
