footer {
    // footerを下に固定
    position: absolute;
    bottom: 0; 
    width: 100%;

    margin-top: 80px;
    text-align: center;
    background: #F7F7F7;
    @include breakpoint(large-up) {
        margin-top: 50px;
        grid-row: 2 / 2;
        grid-column: 2 / 3;
    }
    .inner {
        padding: 10px 0;
        font-size: 12px;
        color: #666;
    }
}