#news  {
    .single-news {
        h1 {
            margin-bottom: 40px;
        }
        time {
            font-size: 14px;
            color: #B7B7B7;
        }
        h3 {
            font-size: 16px;
            border-bottom: 1px solid #B7B7B7;
            padding-bottom: 15px;
            margin-bottom: 20px;
            margin-top: 5px;
        }
        .site-link {
            border-bottom: 1px solid;
        }
        .site-link:hover {
            color: #FFE533;
        }
        img {
            width: 100%;
            @include breakpoint(medium-up) {
                width: 95%;
                margin: 0px 20px;
            }
        }
    }
}
		

			