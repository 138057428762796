body.page-template-about {
	.container {
		h1 {
			text-align: center;
		}
	}
	#about {
		.container {
			margin-bottom: 150px;
		}
		h2 {
			font-size: 22px;
			margin-top: 30px;
			margin-bottom: 0px;
		}
		h3 {
			font-size: 16px;
			text-align: center;
			font-weight: normal;
			letter-spacing: 1px;
		}
		p {
			text-align: left;
			line-height: 42px;
			@include breakpoint(medium-up) {
				text-align: center;
			}
		}
	}
	#company {
		table {
			width: 100%;
		}
		tr:nth-child(odd) {
			background: #F7F7F7;
		}
		th {
			width: 34%;
		}
		td {
			padding: 10px 20px;
			@include breakpoint(large-up) {
				padding: 20px 20px;
			}
			p {
				margin: 0;
				@include breakpoint(large-up) {
					display: inline;
				}
			}
		}
		.ggmap {
			margin-top: 30px;
			position: relative;
			padding-bottom: 80%;
			@include breakpoint(large-up) {
				margin-top: 110px;
				padding-bottom: 60%;
			}
		}   
		.ggmap iframe,
		.ggmap object,
		.ggmap embed {
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.station {
			margin-top: 10px;
		}
	}
}

