body.page-template-service {
    .container {
        // @include breakpoint(large-up) {
        //     max-width: 1000px;
        //     margin: 0 auto;
        // }
    }
    .back-image {
        background-image: url(../images/back-image.jpg);
        height: 160px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include breakpoint(large-up) {
            height: 400px;
            // width: 100%;
        }
    }
    .ec-message {
        background-color: white;
        margin-top: -100px;
        margin-bottom: 100px;
        padding: 20px;
        padding-top: 30px;
        @include breakpoint(medium-up) {
            text-align: center;
        }
        @include breakpoint(large-up) {
            padding: 50px;
            padding-top: 50px;
        }
        .shop-name {
            font-family: serif;
            h2 {
                font-size: 26px;
                font-weight: normal;
                @include breakpoint(large-up) {
                    font-size: 32px;
                }
                small {
                    display: block;
                    font-size: 16px;
                    font-weight: normal;
                }
            }
            @include breakpoint(large-up) {
                margin-bottom: 50px;
            }
        }
        p {
            line-height: 35px;
            margin-bottom: 30px;
            @include breakpoint(large-up) {
                margin-bottom: 60px;
            }
        }
        figure {
            margin: 0;
            @include breakpoint(large-up) {
                margin: 0 80px;
            }
        }
    }
    @keyframes infinity-scroll-left {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
    }
    .infinite-slider .swiper-wrapper {
        transition-timing-function: linear;
      }
      .infinite-slider .swiper-slide {
        height: 210px !important; /* 高さを指定 */
        width: auto !important;
      }
      .infinite-slider .swiper-slide img {
        width: auto;
        height: 100%;
      }
    .ec-important {
        margin-bottom: 40px;
        h4 {
            margin-bottom: 30px;
            font-weight: normal;
        }
        .border {
            display: flex;
            align-items: center;
        }
         
        .border:before,
        .border:after {
            content: "";
            height: 1px;
            flex-grow: 1;
            background-color: black;
        }
         
        .border:before {
            margin-right: 1rem;
        }
         
        .border:after {
            margin-left: 1rem;
        }
        ol {
            margin-bottom: 30px;
            // margin: 0 20px 30px 20px;
            padding: 30px;
            background-color: #FAFAFA;
            li {
                margin-bottom: 20px;
            }
            li:last-child {
                margin: 0;
            }
        }
        @include breakpoint(large-up) {
            margin-bottom: 80px;
            ol, p {
                // margin: 0 20px;
            }
            ol {
                margin-bottom: 30px;
            }
        }
    }
    .ec-link {
        display: block;
        text-align: center;
        @include breakpoint(large-up) {
            margin-bottom: 50px;
        }
    }
    .ec-link:hover {
         img {
            opacity: 0.6;
        }
    }
}