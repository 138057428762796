// .works-listは_common.scssに記載

#works  {
	.container {
		@include breakpoint(large-up) {
			padding: 0;
			max-width: none;
		}
		nav {
			width: 80%;
			margin: 50px auto;
			.toggle_menu {
				position: relative;
				text-align: left;
				letter-spacing: 2px;
				background: #F7F7F7;
				margin: 0;
				padding: 10px 30px;
			}
			
			.toggle_menu:after {
				content: "";
				display: inline-block;
				position: absolute;
				font-family: "Line Awesome Free","Line Awesome Brands";
				content: "\f107";
				font-weight: 900;
				right: 24px;
				z-index: 10;
				top: 12px;
				
				
			}
			
			.open_close {
				display: none;
				// .category_2 {
				// 	margin-top: 1px;
				// 	position: relative;
				// 	&:after {
				// 		position: absolute;
				// 		font-family: "Line Awesome Free","Line Awesome Brands";
				// 		content: "\f067";
				// 		font-weight: 900;
				// 		right: 26px;
				// 		z-index: 10;
				// 		top: 12px;
				// 	}
				// 	&.li_open {
				// 		&:after {
				// 			content: "\f068";
				// 		}
				// 	}
				// }
				
				span {
					display: block;
					position: relative;
					text-align: left;
					font-weight: bold;
					letter-spacing: 2px;
					background: #F7F7F7;
					margin: 0;
					padding: 10px 30px;
					a {
						position: relative;
						color: #000;
					}
					a:hover {
						color: #FFE533;
					}
				}

				span:hover {
					color: #FFE533;
				}
				.close_open {
					display: none;
					li {
						background: #000;
						padding: 10px 30px;
						margin-top: 1px;
						a {
							color: #fff;
							letter-spacing: 2px;
						}
						a:hover {
							color: #FFE533;
						}
					}
				}
			}
		}
	}
}
		

			