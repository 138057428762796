body.page-template-contact {
    .text {
        margin-bottom: 30px;
        @include breakpoint(medium-up) {
            text-align: center;
        }
    }
    .contact {
        .mw_wp_form {
            .item {   
                p {
                    padding: 15px 0;
                    // @include breakpoint(large-up) {
                    //     border-top: 0.5px solid;
                    // }
                    input,textarea {
                        width: 100%;
                        margin-top: 10px;
                        padding: 10px;
                    }
                }
            }
            .button-area {
                
                .button {
                    background: #ffe500;
                    color: #000;
                    border: 0;
                    display: block;
                    margin: 0 auto;
                    padding: 17px 50px;
                    border-radius: 60px;
                }
                .button:hover {
                    opacity: 0.6;
                }
            }
        }
    }
    
    .confirm {
        .text {
            text-align: center;
        }
        .item{
            background: #F7F7F7;
            padding: 40px; 
            p {
                label {
                    border-bottom: 1px dotted;
                }
            }
        }
        .button-area {
            display: flex;
            justify-content: space-around;
            .button {
                background: #ffe500;
                color: #000;
                border: 0;
                display: block;
                margin: 0 auto;
                padding: 15px 30px;
                border-radius: 60px;
            }
            .button:hover {
                opacity: 0.6;
            }
        }
    }
    .error {
        color: red;
    }

    .complete {
        .message-box {
            text-align: center;
            background: #847B78;
            padding: 30px;
            color: white;   
            margin-bottom: 30px;
            @include breakpoint(medium-up) {
                margin: 0 auto;
                margin-bottom: 30px;
                width: 450px;
            }
        }
        p {
            @include breakpoint(medium-up) {
                text-align: center;
                margin-bottom: 70px;
            }
        }
    }
}