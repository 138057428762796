#global_wrap {
	// footerを下に固定
	font-size: 15px;
	min-height: 100vh;

  position: relative;/*←相対位置*/
	@include breakpoint(large-up) {
		display: grid;
		grid-template-columns: 190px 1fr;
	}
}

li {
	list-style: none;
}

ul.works-list {
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	// @include breakpoint(large-up) {
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	// justify-content: space-between;
	// }
	li {
		margin-bottom: 15px;
		overflow: hidden;
		position: relative;
		border: 0.5px solid #C9C9C9;
		width: calc((100% - 15px) / 2);
    margin-right: 15px;

		@include breakpoint(medium-up) {
			margin-bottom: 20px;
			width: calc((100% - 20px) / 2);
			margin-right: 20px;
		}

		@include breakpoint(large-up) {
			width: calc((100% - 90px) / 4);
			margin-bottom: 25px;
			margin-right: 30px;
		}
		
	
		
		// @include breakpoint(large-up) {
		// 		width: 32%;
		// }
		// @include breakpoint(xlarge-up) {
		// 		width: 24%;
		// }
		img {
			width: 100%;
		}

		a {
			figure {
				margin: 0;
			}
			.mask {
				position: relative;
			}
			.title {
				position: absolute;
				padding: 10% 10% 0 10%;

				// width: 100%;
				// height: 100%;
				// display: flex;
				// justify-content: center;
				// align-items: center;
				top: 0;
				left: 0;
				z-index: 10;
				visibility: hidden;
				h5 {
					color: black;
					margin: 0;
					font-size: 16px;
				}
				.category-name {
					color: black;
					font-size: 14px;
				}
			}
			&:hover {
				.mask {
					&::after {
						content: "" ; 
						width: 100%;
						height: 100%;
						display: block;
						background-color: rgba(255,229,0,0.8);
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				.title {
					visibility: visible;
				}
			}
			
		}
	}
	li:nth-of-type(4n) {
		@include breakpoint(large-up) {
			margin-right: 0;
		}
	}
	li:nth-of-type(2n) {
		@media screen and (max-width:1023px) {
			margin-right: 0;
		}
	}
}

main {
	padding-bottom: 60px;
	margin-top: 130px;
	
	@include breakpoint(large-up) {
		margin-top: 20px;
		margin-right: 20px;
		margin-left: 20px;
		padding-bottom: 100px;
	}
	h1 {
		text-align: center;
		font-size: 40px;
		letter-spacing: 3px;
		margin-bottom: 40px;
	}
	
	h2 {
		text-align: center;
		letter-spacing: 3px;
		margin-bottom: 30px;
		
		@include breakpoint(large-up) {
			font-size: 40px;
		}
	}
	.container {
		padding: 0 20px;
		margin-top: 70px;
		margin-bottom: 40px;
		@include breakpoint(medium-up) {
			padding: 0 50px;
		}
		@include breakpoint(large-up) {
			// padding: 0 220px;
			max-width: 1000px;
			margin: 0 auto;
			margin-top: 70px;
		}
		
	}
	// .sub_container {
	// 	@include breakpoint(large-up) {
	// 		margin: 0 150px;
	// 	}
	// }
	
	ul {
		padding: 0;
	}
	
	.button-area {
		text-align: center;
		margin-top: 35px;
		margin-bottom: 50px;
		@include breakpoint(large-up) {
			// margin-bottom: 0;
		}
		.button {
			display: inline-block;
		}
		.button.border {
			background: #ffe500;
			color: #000;
			border: none;
			font-size: 14px;
			letter-spacing: 1px;
		}
		a {
			font-weight: bold;
			padding: 17px 50px;
			border-radius: 60px;
		}
		a:hover {
			opacity: 0.6;
		}
	}    
}