#news {
	.archive-news {
		.content_body {
			@include breakpoint(medium-up) {
				margin: 0 100px;
			}
			

			
            @include breakpoint(large-up) {
                // max-width: 850px;
                // margin: 0 auto;
            }
		}
		.news-list {
			li {
				margin-bottom: 20px;
				border-bottom: 1px solid #B7B7B7;
				.caption {
					margin-bottom: 20px;
					time {
						color: #B7B7B7;
						font-size: 14px;
					}
					h5 {
						color: #000;
						font-weight: normal;
						margin: 0;
						margin-top: 3px;
						line-height: 24px;
					}
				}
				a {
					@include breakpoint(medium-up) {
						display: flex;
						figure {
							width: 50%;
							margin: 0;
						}
						
					}
				}
				a:hover {
					opacity: 0.6;
				}
				@include breakpoint(medium-up) {
					// padding-bottom: 50px;
				}
			}
		}
	}
}
	




	// .news_list {
	// 	border-bottom: 1px solid #B7B7B7;
	// 	margin-top: 30px;
	// 	.news_title {
	// 		time {
	// 			color: #B7B7B7;
	// 			font-size: 14px;
	// 		}
	// 		p {
	// 			color: #000;
	// 		}
	// 	}
	// 	figure {
	// 		margin: 0;
	// 		margin-bottom: 30px;
	// 		margin-top: 30px;
	// 	}
	// }
